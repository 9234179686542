import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import CtaBar from '../components/CtaBar';
import ArrowLink from '../components/ArrowLink';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import mdToHtml from '../util/mdToHtml';
import { PageWrap } from '../styles/common';

export const AssetsDetailTemplate = ({
	name,
	trustee,
	industry,
	content,
	links,
	team,
}) => {
	return (
		<PageWrap>
			<div className="container" style={{ padding: '0 2rem' }}>
				<h3 style={{ margin: '2rem 0 0 0' }}>Assets for Sale:</h3>
				<h2 style={{ margin: 0 }}>{name}</h2>
				<h4 style={{ margin: 0 }}>{industry.map((e) => e.name).join(', ')}</h4>
				<div
					dangerouslySetInnerHTML={{ __html: mdToHtml(content) }}
					style={{ marginBottom: '4rem' }}
				/>
				<div className="columns" style={{ marginBottom: '4rem' }}>
					{links && (
						<div className="column">
							<BottomHeading>Links:</BottomHeading>
							<ul>
								{links.map(({ name, url }, index) => (
									<li key={index}>
										<a href={url} target="_blank" rel="noopener noreferrer">
											{name}
										</a>
									</li>
								))}
							</ul>
						</div>
					)}
					{trustee && (
						<div className="column">
							<BottomHeading>For inquiries please contact:</BottomHeading>
							{team
								.filter(({ node }) => trustee === node.name)
								.map(({ node }, index) => (
									<TrusteeTile key={index}>
										<ImageWrap>
											<img src={node.round_image} alt="" />
										</ImageWrap>
										<ContentWrap>
											<h3>{node.name}</h3>
											<h4>{node.title}</h4>
											<ArrowLink href={node.fields.slug}>Contact</ArrowLink>
										</ContentWrap>
									</TrusteeTile>
								))}
						</div>
					)}
				</div>
			</div>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const AssetsDetail = ({ data }) => {
	const { name, trustee, industry, content, links } = data.assetsJson;
	const team = data.allTeamJson.edges;

	return (
		<Layout>
			<Helmet>
				<title>{name} | Spergel Corporate</title>
				<style>{`div#chat-widget-container {display: none;}`}</style>
			</Helmet>
			<AssetsDetailTemplate
				name={name}
				trustee={trustee}
				industry={industry}
				content={content}
				links={links}
				team={team}
			/>
		</Layout>
	);
};

export default AssetsDetail;

export const assetDetailQuery = graphql`
	query Asset($id: String!) {
		assetsJson(id: { eq: $id }) {
			fields {
				slug
			}
			name
			trustee
			industry {
				name
			}
			content
			links {
				name
				url
			}
			fields {
				slug
			}
		}
		allTeamJson {
			edges {
				node {
					round_image
					name
					title
					fields {
						slug
					}
				}
			}
		}
	}
`;

const TrusteeTile = styled.div`
	display: flex;
	margin: 1rem;
`;

const ImageWrap = styled.div`
	flex-basis: 160px;
	img {
		width: 100%;
	}
`;

const ContentWrap = styled.div`
	flex-basis: 200px;
	flex-grow: 1;
	padding: 1rem 0 1rem 2rem;
	a {
		margin-left: 0;
		font-size: 1rem;
	}
	h3 {
		@media only screen and (min-width: 768px) {
			font-size: 2rem;
		}
		font-size: 1.25rem;
		margin: 0;
		font-weight: 300;
	}
	h4 {
		margin: 0;
		font-weight: 300;
		margin-bottom: 1rem;
	}
`;

const BottomHeading = styled.h4`
	font-size: 1.5rem;
	font-weight: 300;
	margin-bottom: 2rem;
`;
